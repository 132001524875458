<template>
  <div
    class="testing px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Testing" size="large" />
    <img src="@/assets/disposalexplosion-min.jpg" alt="Testing" />
    <div class="flex flex-wrap pt-8">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <TitleComponent title="Services" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <HeadingComponent
          title="SMS is a Premier Explosives Testing Lab Approved by the USDOT"
        />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) has decades of experience
            testing energetic substances and articles. We offer a variety of
            explosives testing resources and are authorized by the United States
            Department of Transportation to recommend shipping classifications
            for Class 1 substances and articles as indicated
            <a
              class="link text-red-800"
              href="https://www.phmsa.dot.gov/hazmat/energetic-materials-approvals/explosive-test-labs"
              target="_new"
              >here.</a
            >
          </p>
          <p>
            Please contact us by using the chat window at the bottom right of
            the screen, calling 801-567-0456, or filling out the
            <router-link class="text-red-800 link" to="/contact"
              >form here.</router-link
            >
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="pt-8 flex flex-wrap image-row-link">
      <div class="w-full md:w-1/3">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-4 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/testing/sensitivity-testing"
              aria-label="d3support"
              ><img
                src="@/assets/Testing/MBOM-Impact-Sample-Application-min.jpg"
                width="242"
                height="155"
                alt=""
                class="img-responsive wp-image-12301"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              Sensitivity Testing
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/3">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-4 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/testing/dottesting"
              aria-label="d3support"
              ><img
                src="@/assets/Testing/dottesting-min.jpg"
                width="242"
                height="155"
                alt=""
                class="img-responsive wp-image-12301"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              DOT Classification Testing
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/3">
        <div
          class="fusion-column-wrapper p-4"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            style="-webkit-box-shadow: 3px 3px 7px rgba(0,0,0,0.3);box-shadow: 3px 3px 7px rgba(0,0,0,0.3);"
            class="fusion-imageframe imageframe-dropshadow imageframe-5 hover-type-none"
            ><router-link
              class="fusion-no-lightbox"
              to="/testing/in-process-simulation-testing"
              aria-label="Facility Siting and Design"
              ><img
                src="@/assets/Testing/inprocesstest-min.jpg"
                width="242"
                height="155"
                alt="Facility Siting and Design"
                class="img-responsive wp-image-12294"/></router-link
          ></span>
          <div class="fusion-sep-clear"></div>
          <div
            class="fusion-separator fusion-full-width-sep sep-none"
            style="margin-left: auto;margin-right: auto;margin-top:10px;margin-bottom:10px;"
          ></div>
          <div class="fusion-text">
            <h2
              data-fontsize="18"
              data-lineheight="27px"
              class="fusion-responsive-typography-calculated text-left"
              style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
            >
              In-Process Simulation Testing
            </h2>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";
import HeadingComponent from "../../components/HeadingComponent";

export default {
  metaInfo: {
    title: "Testing Experience",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has decades of experience in sensitivity, DOT/DOD classification, and in process simulation testing."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent,
    HeadingComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `SMS is an approved and authorized Examining Agency to perform`,
          contentLink: {
            text: "DOT Classification testing",
            to: "/testing/dottesting"
          },
          content2: ` explosives. These services are for determining the DOT transportation classification, including the proper shipping name, hazard class and division, and compatibility group for explosive substances and articles. We are also experience in completing DoD testing.`,
          title: "DOT/DOD Classification Testing"
        },
        {
          contentLink: {
            text: "In Process testing",
            to: "/testing/in-process-simulation-testing"
          },
          content2: `is used to characterize and classify substance and article risk when in-process during manufacturing operations. Tests are used to identify responses to insults for specific configurations as energetic material responses are highly dependent on their configurations.`,
          title: "In-Process Simulation Testing"
        },
        {
          contentLink: {
            text: "Sensitivity testing",
            to: "/testing/sensitivity-testing"
          },
          content2: `is completed to determine the safe handling conditions for various insults. SMS personnel are intimately familiar with test methodologies, equipment, data acquisition, and data evaluation.`,
          title: "Sensitivity Testing"
        },
        {
          content1: `SMS has partnered with the Tooele Army Depot (TEAD) to yield a very capable`,
          contentLink: {
            text: "test site",
            to: "/testing/test-site-at-tead"
          },
          content2: `to perform large-scale and small-scale tests of energetic substances and articles. The TEAD/SMS partnership provides a unique set of capabilities for government and commercial clients.`,
          title: "SMS Test Site at TEAD"
        },
        {
          content1: `SMS has`,
          contentLink: {
            text: "significant resources",
            to: "/testing/sms-testing-assets"
          },
          content2: `to accomplish DOT/DOD testing for Class 1 (explosive), Class 4 (Flammable Solids), and Class 5 (Oxidizing Agents and Organic Peroxides). Visit the Testing Assets page to learn more.`,
          title: "Testing Assets"
        },
        {
          content1: `SMS is experienced with`,
          contentLink: {
            text: "modeling",
            to: "/risk-management/modeling-and-analysis"
          },
          content2: `burning and exploding substances and articles inside process equipment and other confining structures. We have modeled heat transfer, propellant kinetics, gas pressurization, and material yielding and fracturing. SMS is proficient at various software solutions such as BlastX, IVM, and COMSOL.`,
          title: "Modeling and Analysis"
        },
        {
          content1: `Safety Management Services, Inc. has the capability to obtain`,
          contentLink: {
            text: "high-speed images",
            to: "/testing/high-speed-video"
          },
          content2: `of explosions showing the resultant metal morphology as the metal deforms and expands. SMS can provide such services for a wide variety of applications and sizes.`,
          title: "High-speed Video"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.testing .image-row-link h2 {
  font-size: 18px;
  color: #333333;
}
</style>
